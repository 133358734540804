export const SPEAKER_BASIC_INFO_FRAGMENT = `
  fragment speakerBasicInfoFragment on Speaker {
    uid
    bio
    jobTitle
    homepageUrl
    twitterUrl
    facebookUrl
    instagramUrl
    pinterestUrl
  }
`;
